var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dots-wrapper"},_vm._l((_vm.mount),function(dot){return _c('div',{key:dot,staticClass:"dot",class:{
      fill: dot <= _vm.intensity,
      'dot--xs': _vm.size === 'xs',
      'dot--s': _vm.size === 's',
      'dot--m': _vm.size === 'm',
      'dot--l': _vm.size === 'l',
      circle: !_vm.quad,
    },style:({
      backgroundColor: dot <= _vm.intensity ? _vm.color || '#000' : '',
      borderColor: _vm.color || '#000'
    })})}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }