<template>
  <div class="dots-wrapper">
    <div
      class="dot"
      v-for="dot in mount"
      :key="dot"
      :class="{
        fill: dot <= intensity,
        'dot--xs': size === 'xs',
        'dot--s': size === 's',
        'dot--m': size === 'm',
        'dot--l': size === 'l',
        circle: !quad,
      }"
      :style="{
        backgroundColor: dot <= intensity ? color || '#000' : '',
        borderColor: color || '#000'
      }"
    />
  </div>
</template>
<script>
export default {
  props: {
    intensity: {
      type: Number,
      default: 0,
    },
    mount: {
      type: Number,
      default: 5,
    },
    size: {
      type: String,
      validator: (prop) => ["xs", "s", "m", "l"].includes(prop),
      default: "m",
    },
    quad: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String
    }
  },
};
</script>

<style lang="stylus" scoped>
$dot-size-xs = vw(6px)
$dot-size-s = vw(9px)
$dot-size-m = vw(12px)
$dot-size-l = vw(16px)

.dots-wrapper
  align-items: center
  display: flex

  .dot
    border: 1px solid #000

    &--xs
      flex: 0 0 $dot-size-xs
      height: $dot-size-xs
      margin-right: $dot-size-xs
      width: $dot-size-xs

    &--s
      flex: 0 0 $dot-size-s
      height: $dot-size-s
      margin-right: $dot-size-s
      width: $dot-size-s

    &--m
      flex: 0 0 $dot-size-m
      height: $dot-size-m
      margin-right: $dot-size-m
      width: $dot-size-m

    &--l
      flex: 0 0 $dot-size-l
      height: $dot-size-l
      margin-right: $dot-size-l
      width: $dot-size-l

    &.fill
      background-color: #000

    &.circle
      border-radius: 100%
</style>