<template>
  <div class="intensities" :class="{ end }">
    <table class="intensities-table" v-if="perfume.gender!=='F'">
      <tr v-if="perfume.manIntensity">
        <td>{{ $t("intensity") }}</td>
        <td>
          <IntensityDots
            :quad="dotsStyle.quad"
            :size="dotsStyle.size"
            :intensity="perfume.manIntensity"
            :color="color"
          />
        </td>
      </tr>
      <tr v-if="perfume.manFreshness">
        <td>{{ $t("freshness") }}</td>
        <td>
          <IntensityDots
            :quad="dotsStyle.quad"
            :size="dotsStyle.size"
            :intensity="perfume.manFreshness"
            :color="color"
          />
        </td>
      </tr>
      <tr v-if="perfume.manLasting">
        <td>{{ $t("lasting") }}</td>
        <td>
          <IntensityDots
            :quad="dotsStyle.quad"
            :size="dotsStyle.size"
            :intensity="perfume.manLasting"
            :color="color"
          />
        </td>
      </tr>
    </table>
    <table class="intensities-table" v-else>
      <tr v-if="perfume.womanSweet">
        <td>{{ $t("sweet") }}</td>
        <td>
          <IntensityDots
            :quad="dotsStyle.quad"
            :size="dotsStyle.size"
            :intensity="perfume.womanSweet"
            :color="color"
          />
        </td>
      </tr>
      <tr v-if="perfume.womanFloral">
        <td>{{ $t("floral") }}</td>
        <td>
          <IntensityDots
            :quad="dotsStyle.quad"
            :size="dotsStyle.size"
            :intensity="perfume.womanFloral"
            :color="color"
          />
        </td>
      </tr>
      <tr v-if="perfume.womanLasting || perfume.manLasting">
        <td>{{ $t("lasting") }}</td>
        <td>
          <IntensityDots
            :quad="dotsStyle.quad"
            :size="dotsStyle.size"
            :intensity="perfume.womanLasting || perfume.manLasting"
            :color="color"
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import queryStringMixin from "../mixins/queryStringMixin";
import IntensityDots from "./IntensityDots.vue";
export default {
  mixins: [queryStringMixin],
  props: {
    perfume: { type: Object, required: true },
    dotsStyle: {
      type: Object,
      default: () => ({ quad: true, size: "xs" }),
    },
    color:String,
    end: Boolean,
  },
  components: { IntensityDots },
};
</script>

<style lang="stylus" scoped>
.intensities
  margin-bottom: vw(30px)
  margin-top: vw(30px)

  .mobile &
    margin-bottom: vw(20px)
    margin-top: vw(10px)

  &.end
    > table
      width: 100%

      td
        >>> .dots-wrapper
          justify-content: end

  > table
    td
      &:first-child
        m-font-size(15, 21)
        text-transform: lowercase

  .intensities-table
    border-collapse: collapse

    .mobile &
      height: vw(90px)

    tr
      height: vw(30px)
</style>